<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <div class="content">
        <Header
          :meta="meta"
          :page-title="getModuleConfig('page_title')"
          :page-image="getModuleConfig('page_image')"
          :page-kv-image="getModuleConfig('page_kv_image')"
          :header-section="getModuleConfig('header_section')"
        />
        <!-- Filter Date -->
        <div
          class="
            d-flex
            s-p-3 s-border-b s-border-gray-light
            bg-white
            justify-content-between
            align-items-center
          "
          v-if="showDateFilter"
        >
          <datepicker
            placeholder="請輸入查詢開始時間"
            @input="
              (e) => {
                startAt = fixDate(e);
              }
            "
            :value="startAt"
            bootstrap-styling
            format="yyyy/MM/dd"
            :language="zh"
            class="p-0 field__input mr-3"
            input-class="px-3 text-center"
            :disabled-dates="disabledDates"
            popover-align="center"
            v-if="
              getModuleConfig('filter_by_date') &&
              getModuleConfig('filter_by_date.show_calendar')
            "
            @selected="dateStartChange"
          >
          </datepicker>
          <template
            v-if="
              getModuleConfig('filter_by_date') &&
              getModuleConfig('filter_by_date.show_calendar') &&
              !getModuleConfig('filter_by_date.show_option')
            "
          >
            <div class="mr-3">─</div>
            <datepicker
              placeholder="請輸入查詢結束時間"
              @input="
                (e) => {
                  endAt = fixDate(e);
                }
              "
              :value="endAt"
              bootstrap-styling
              format="yyyy/MM/dd"
              :language="zh"
              class="p-0 field__input"
              input-class="px-3 text-center"
              position="center"
              :disabled-dates="disabledDates"
              @selected="dateEndChange"
            >
            </datepicker>
          </template>

          <select
            class="form-control"
            v-if="
              getModuleConfig('filter_by_date') &&
              getModuleConfig('filter_by_date.show_option')
            "
            @change="dateOptionChange"
            :value="daysDiff"
          >
            <option
              v-for="i in getModuleConfig('filter_by_date.options')"
              :key="i.key"
              :value="i.key"
            >
              {{ i.title }}
            </option>
          </select>
        </div>
        <Tabs :tabs="tabs" :currentTab="currentTab" v-if="!isIndividualTab" />
        <router-view :key="$route.path" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import Tabs from "@/components/Page/Liff/Shared/TabsV2";
import themeColor from "@/mixins/liff/themeColor";
import bookingMixin from "@/mixins/liff/booking";
import _ from "lodash";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";

export default {
  components: {
    Header,
    Tabs,
    Datepicker,
  },
  mixins: [themeColor, bookingMixin],
  data() {
    return {
      zh,
      themeConfigPage: "booking",
      startAt: null,
      endAt: null,
      disabledDates: { from: new Date() },
      daysDiff: 0,
    };
  },
  created() {
    this.debouncedFetch = _.debounce(() => this.fetch(), 500);
  },
  computed: {
    ...mapGetters({
      themeConfig: "liffGeneral/themeConfig",
      meta: "liffGeneral/meta",
    }),
    tabs: function () {
      return _.sortBy(this.getModuleConfig("data_source"), "order").map(
        (tab) => {
          return {
            ...tab,
            query: _.omitBy(
              {
                startAt: this.startAt || undefined,
                endAt: this.endAt || undefined,
              },
              _.isNil
            ),
          };
        }
      );
    },
    currentTab: function () {
      return this.$route.path.split("/").reverse()[0];
    },
    isIndividualTab() {
      return this.$route.name.match(/.*Tab$/);
    },
    showDateFilter() {
      let filter = this.getModuleConfig("filter_by_date");
      return (
        filter &&
        (filter.show_option != undefined ||
          filter.show_calendar != undefined) &&
        (filter.show_option == true || filter.show_calendar == true)
      );
    },
  },
  mounted() {
    this.init();
    if (this.$route.name == "LiffBooking") {
      const isEndWithSlash = this.$route.path.slice(-1) == "/";
      const tab = this.tabs.sort((a, b) => a.order - b.order)[0];
      const path = isEndWithSlash
        ? tab.path
        : `${this.$route.path}/${tab.path}`;
      this.debouncedFetch(path);
    }
  },
  methods: {
    dateStartChange(value) {
      let filter = this.getModuleConfig("filter_by_date");

      this.startAt = moment(value).format("YYYY-MM-DD");
      if (filter.show_option) {
        this.endAt = (
          moment().diff(moment(value).add(this.daysDiff, "days")) < 0
            ? moment()
            : moment(value).add(this.daysDiff, "days")
        ).format("YYYY-MM-DD");
      } else {
        if (moment(this.endAt).diff(moment(value)) < 0) {
          this.endAt = moment().format("YYYY-MM-DD");
        }
      }
      this.debouncedFetch();
    },
    dateEndChange(value) {
      this.endAt = moment(value).format("YYYY-MM-DD");

      if (moment(value).diff(moment(this.startAt)) < 0) {
        this.startAt = moment(value).format("YYYY-MM-DD");
      }
      this.debouncedFetch();
    },
    dateOptionChange(e) {
      let value = e.target.value;

      let filter = this.getModuleConfig("filter_by_date");

      if (filter.show_calendar) {
        if (moment().diff(moment(this.startAt).add(value, "days")) < 0) {
          this.startAt = moment().subtract(value, "days").format("YYYY-MM-DD");

          this.endAt = moment().format("YYYY-MM-DD");
        } else {
          this.endAt = moment(this.startAt)
            .add(value, "days")
            .format("YYYY-MM-DD");
        }
      } else {
        this.startAt = moment().subtract(value, "days").format("YYYY-MM-DD");
        this.endAt = moment().format("YYYY-MM-DD");
      }

      this.daysDiff = value;
      this.debouncedFetch();
    },
    fixDate(e) {
      return moment(e).format("YYYY-MM-DD");
    },
    fetch(path = null) {
      if (this.showDateFilter) {
        if (this.startAt == null || this.endAt == null) return;

        if (
          this.startAt != this.$route.query.startAt ||
          this.endAt != this.$route.query.endAt
        ) {
          const addYear =
            this.getModuleConfig("filter_by_date.show_calendar") || false;
          this.$router.push({
            path: path ?? this.$route.path,
            query: {
              startAt: this.startAt,
              endAt: addYear
                ? this.endAt
                : moment(this.endAt).add(1, "year").format("YYYY-MM-DD"),
            },
          });

          return;
        }
      } else {
        this.startAt =
          this.$route.query.startAt ||
          moment().subtract(1, "year").format("YYYY-MM-DD");
        this.endAt =
          this.$route.query.endAt ||
          moment().add(1, "year").format("YYYY-MM-DD");
        if (
          this.startAt != this.$route.query.startAt ||
          this.endAt != this.$route.query.endAt
        ) {
          this.$router.push({
            path: path ?? this.$route.path,
            query: {
              startAt: this.startAt,
              endAt: this.endAt,
            },
          });
        }
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

select {
  border: 1px solid var(--s-gray-dark);
  border-radius: 0.5rem;
  padding: 0 1rem;
}

.field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-primary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;
    &::placeholder {
      color: #b7b7b7;
    }
    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }
    &.invalid {
      border-bottom: 1px solid #fe0000;
    }
    &:read-only {
      background-color: #f0f0f0;
    }
  }
}

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
}

.s-container {
  min-height: 100vh;
}

.content {
  padding-bottom: 24px;
}

::v-deep .page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
    color: var(--s-primary);
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-bottom: 16px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__button {
    padding: 12px;
  }
}
</style>
