var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"s-layout",style:(_vm.colorObject)},[_c('div',{staticClass:"s-container"},[_c('div',{staticClass:"content"},[_c('Header',{attrs:{"meta":_vm.meta,"page-title":_vm.getModuleConfig('page_title'),"page-image":_vm.getModuleConfig('page_image'),"page-kv-image":_vm.getModuleConfig('page_kv_image'),"header-section":_vm.getModuleConfig('header_section')}}),(_vm.showDateFilter)?_c('div',{staticClass:"\n          d-flex\n          s-p-3 s-border-b s-border-gray-light\n          bg-white\n          justify-content-between\n          align-items-center\n        "},[(
            _vm.getModuleConfig('filter_by_date') &&
            _vm.getModuleConfig('filter_by_date.show_calendar')
          )?_c('datepicker',{staticClass:"p-0 field__input mr-3",attrs:{"placeholder":"請輸入查詢開始時間","value":_vm.startAt,"bootstrap-styling":"","format":"yyyy/MM/dd","language":_vm.zh,"input-class":"px-3 text-center","disabled-dates":_vm.disabledDates,"popover-align":"center"},on:{"input":function (e) {
              _vm.startAt = _vm.fixDate(e);
            },"selected":_vm.dateStartChange}}):_vm._e(),(
            _vm.getModuleConfig('filter_by_date') &&
            _vm.getModuleConfig('filter_by_date.show_calendar') &&
            !_vm.getModuleConfig('filter_by_date.show_option')
          )?[_c('div',{staticClass:"mr-3"},[_vm._v("─")]),_c('datepicker',{staticClass:"p-0 field__input",attrs:{"placeholder":"請輸入查詢結束時間","value":_vm.endAt,"bootstrap-styling":"","format":"yyyy/MM/dd","language":_vm.zh,"input-class":"px-3 text-center","position":"center","disabled-dates":_vm.disabledDates},on:{"input":function (e) {
                _vm.endAt = _vm.fixDate(e);
              },"selected":_vm.dateEndChange}})]:_vm._e(),(
            _vm.getModuleConfig('filter_by_date') &&
            _vm.getModuleConfig('filter_by_date.show_option')
          )?_c('select',{staticClass:"form-control",domProps:{"value":_vm.daysDiff},on:{"change":_vm.dateOptionChange}},_vm._l((_vm.getModuleConfig('filter_by_date.options')),function(i){return _c('option',{key:i.key,domProps:{"value":i.key}},[_vm._v(" "+_vm._s(i.title)+" ")])}),0):_vm._e()],2):_vm._e(),(!_vm.isIndividualTab)?_c('Tabs',{attrs:{"tabs":_vm.tabs,"currentTab":_vm.currentTab}}):_vm._e(),_c('router-view',{key:_vm.$route.path})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }